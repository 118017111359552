"use client";
import React, { Context, createContext, useState } from "react";

import { QREditorContextProps, QRProps } from "./types";
import { QrStyleType } from "@/types/qr";
import { FrameType } from "@/containers/MyQrCodeDetailsContainer/components/QRFrames/types";

export const INITIAL_QR_DATA: QRProps<any> = {
  data: {
    typeName: "",
    themeColor: {
      buttonColor: "#1466b8",
      backgroundColor: "#d1e5fa",
    },
  },
  qrStyle: {
    styleType: QrStyleType.STANDARD,
    backgroundColor: "#FFFFFF",
    dotsStyle: "square",
    dotsColor: "#000000",
    cornerStyle: "rounded-dot",
    cornerBorderColor: "#000000",
    cornerBackgroundColor: "#000000",
    logoUrl: "",
    frameStyle: "none" as FrameType,
    frameColor: "#404040",
    frameBackgroundColor: "#FFFFFF",
    frameTextColor: "#FFFFFF",
    frameText: "SCAN ME!",
  },
  type: "",
  isValidForm: false,
  currentPageIndex: 0,
  previousPageIndex: 0,
  ai: undefined,
};

let QREditorContext: Context<QREditorContextProps<undefined>> | undefined;
const { Provider } = (QREditorContext = createContext<QREditorContextProps<undefined>>(undefined));

const QREditorProvider = ({ children }: any) => {
  const [qrData, setQrDataState] = useState<QRProps<any>>(INITIAL_QR_DATA);
  const [eventSource, setEventSource] = React.useState(undefined);
  //prevenir evento duplicado en Amplitude
  //valida si el QR se ha seleccionado en qr-editor o al usar next button
  const [gridSelectEvent, setGridSelectEvent] = React.useState(false);
  const [isQRfinished, setIsQRfinished] = React.useState(false);
  const [isQrLoading, setIsQrLoading] = React.useState(false);
  // valida inputs y casos que no tienen reglas de validación: Youtube valid url, Links, Imagenes requeridas
  const [validationError, setValidationError] = React.useState(false);

  const setQrData = (data: QRProps<undefined>, omitLocalStorage?: string[]) => {
    setQrDataState(data);
  };

  React.useEffect(() => {
    setEventSource(undefined);
    setIsQRfinished(false);
    setGridSelectEvent(false);
    setIsQrLoading(false);
    setValidationError(false);
  }, []);

  return (
    <Provider
      value={{
        qrData,
        setQrData,
        eventSource,
        setEventSource,
        isQRfinished,
        setIsQRfinished,
        gridSelectEvent,
        setGridSelectEvent,
        isQrLoading,
        setIsQrLoading,
        validationError,
        setValidationError,
      }}>
      {children}
    </Provider>
  );
};

export { QREditorContext, QREditorProvider };
