import { Cookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";

import { getSiteDomain } from "@/utils/site";

import { AUTHORIZATION } from "@/internals/constants/cookies";
import { PATHS } from "@/internals/constants/paths";

import { Token, TokenData } from "./types";

export const setCookie = (name: string, value: any, expiration?: Date) => {
  const cookies = new Cookies();
  cookies.set(name, value, {
    domain: getCookieDomain(),
    expires: expiration ? new Date(expiration) : undefined,
    path: PATHS.HOME,
  });
};

export const getCookie = (name: string) => {
  const cookies = new Cookies();
  return cookies.get(name);
};

export const removeCookie = (name: string) => {
  const cookies = new Cookies();
  cookies.remove(name, { path: PATHS.HOME, domain: getCookieDomain() });
};

export const getAuthCookie = () => {
  const cookies = new Cookies();
  return cookies.get(AUTHORIZATION);
};

export const setAuthCookie = (token: string, expiration?: string) => {
  const cookies = new Cookies();

  cookies.set(AUTHORIZATION, token, {
    domain: getCookieDomain(),
    expires: expiration ? new Date(expiration) : undefined,
    path: PATHS.HOME,
  });
};

export const removeAuthCookie = () => {
  const cookies = new Cookies();
  cookies.remove(AUTHORIZATION, {
    domain: getCookieDomain(),
    path: PATHS.HOME,
  });
};

export const isTokenExpired = (token: string) => {
  const decoded = jwtDecode(token) as Token;
  return Date.now() >= decoded.exp * 1000;
}

export const getTokenData = (): TokenData => {
  const cookies = new Cookies();
  const token = cookies.get(AUTHORIZATION);

  if (!token) return undefined;
  
  return {
    token,
    isTokenExpired: isTokenExpired(token),
  };
};

export const isUserLoggedIn = () => {
  const cookies = new Cookies();
  return cookies.get(AUTHORIZATION);
};

export const getCookieDomain = () => {
  if (process.env.NEXT_PUBLIC_ENV === "local") {
    return "localhost";
  } else {
    return `.${getSiteDomain()}`;
  }
};
