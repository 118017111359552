const indexedPaths = {
  "fastqrgenerator.com": ["/", "/contact-us"],
  "qrcreator.com": ["/", "/contact-us"],
  "onlineqrcode.com": [],
  "app.onlineqrcode.com": []
};

module.exports = {
  indexedPaths,
};
